<template>
	<el-card class="create-app">
		<div class="title">创建自建应用</div>
		<div class="step-tip">
			<div>
				（1）进入企业微信后台，依次点击应用管理→应用，自建应用区域，点击【创建应用】，并填写以下应用信息：
				<el-link type="primary" style="line-height: 20px;" @click="handleHelp(0)">点击查看创建教程</el-link>
			</div>
			<div style="margin-left: 34px;">● 应用Logo：请
				<el-link type="primary" href="/20210811-dac8af19-f344-476f-b0f5-243c23e38410.png"  download>点击此处下载</el-link>
				，并上传
			</div>
			<div style="margin-left: 34px;">● 应用名称请填写：<span style="font-weight: bold;font-size: 14px;color: #409EFF;">企店助手</span>
			</div>
			<div style="margin-left: 34px;">● 应用介绍不用填写</div>
			<div style="margin-left: 34px;">● 设置可见范围：选择部门或成员</div>
		</div>

		<div class="step-tip">
			（2）应用创建成功后，将应用详情中的AgentId和Secret填入到下方输入框中
			<el-link type="primary" style="line-height: 20px;margin-left: 10px;" @click="handleHelp(1)">点击查看配置教程</el-link>
		</div>

		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px" style="margin-top: 20px;">
			<el-form-item label="AgentId" prop="AgentId">
				<el-input v-model="ruleForm.AgentId" style="max-width: 400px;" placeholder="请填写应用AgentId"></el-input>
			</el-form-item>
			<el-form-item label="Secret" prop="Secret">
				<el-input v-model="ruleForm.Secret" style="max-width: 400px;" placeholder="请填写应用Secret"></el-input>
			</el-form-item>
		</el-form>

		<div class="step-tip">
			（3）在应用详情中，【开发者接口】—【网页授权及JS-SDK】模块下，点击【设置可信域名】，复制下方可信域名填写到输入框内，并且上传文件进行域名校验
			<el-link type="primary" style="line-height: 20px;" @click="handleHelp(2)">点击查看校验教程</el-link>
		</div>

		<div class="step3-tip">
			● 复制下方可信域名，并在企业微信后台对应输入框中填入
		</div>
		<div class="set-info">
			<div class="left">
				<div class="tit">可信域名：</div>
				<div class="txt">{{result.TrustedDomain}}</div>
			</div>
			<div class="copy">
				<el-button type="primary" size="mini" v-clipboard:copy="result.TrustedDomain"
					v-clipboard:success="onCopy" v-clipboard:error="onError">复制</el-button>
			</div>
		</div>

		<div class="step3-tip">
			● 依次点击【申请校验域名】→【下载文件】，文件下载成功后，点击下方【上传】，选择下载文件
		</div>
		<el-form :model="ruleForm1" :rules="rules1" ref="ruleForm1" label-width="90px" style="margin-top: 20px;">
			<el-form-item label="校验文件:" prop="file">
				<div class="upload-wraper">
					<div v-if="ruleForm1.file" style="color: #666;margin-right: 20px;">{{ruleForm1.file}}</div>
					<div>
						<el-upload action="https://wapapi.qidian.shop/verify/upload" :show-file-list="false"
							:on-success="handleSuccess" :before-upload="handleBeforeUpload">
							<el-button type="primary" size="mini" v-if="!ruleForm1.file">上传</el-button>
							<el-button type="primary" size="mini" v-else>重新上传</el-button>
						</el-upload>
					</div>
				</div>
			</el-form-item>
		</el-form>

		<div class="step-tip">
			（4）前往客户联系→客户，展开API，可调应用勾选【企店助手】
			<el-link type="primary" style="line-height: 20px;" @click="handleHelp(3)">点击查看配置入口</el-link>
		</div>

		<div class="footer">
			<el-button type="primary" style="width: 300px;" @click="handleNext">我已配置完成</el-button>
		</div>
		
		<el-dialog :visible.sync="visible" :title="helpData.title" width="850px">
			<img :src="helpData.img" style="display: block;width: 100%;">
		</el-dialog>

	</el-card>
</template>

<script>
	import {
		qyweixinsidebarconfig,
		setqyweixinsidebarconfig
	} from '@/api/sv1.0.0.js';

	export default {
		components: {},
		data() {

			var validateFile = (rule, value, callback) => {
				console.log(value)
				if (value === '') {
					callback(new Error('请上传校验文件'));
				} else {
					callback();
				}
			};

			return {
				isBuild: false,

				result: {},

				ruleForm: {
					AgentId: '',
					Secret: ''
				},
				rules: {
					AgentId: [{
						required: true,
						message: '请填写自建应用【企店助手】的AgentId',
						trigger: 'blur'
					}],
					Secret: [{
						required: true,
						message: '请填写自建应用【企店助手】的Secret',
						trigger: 'blur'
					}]
				},

				ruleForm1: {
					file: ''
				},
				rules1: {
					file: [{
						required: true,
						trigger: 'change',
						validator: validateFile
					}],
				},
				
				
				helpList: [{
					title: '如何创建自建应用？',
					img: 'https://cdn.dkycn.cn/melyshop/image/20210810-980b5c45-27fb-4fcf-80d5-f3f76e78b444.png'
				},{
					title: '如何配置AgentId和Secret？',
					img: 'https://cdn.dkycn.cn/melyshop/image/20210810-c260bd18-67c9-420e-9b35-2ef3c0275507.png'
				},{
					title: '如何校验域名？',
					img: 'https://cdn.dkycn.cn/melyshop/image/20210810-c47386e1-6ed5-406f-b12f-051eb42d8754.png'
				},{
					title: '如何配置可调应用？',
					img: 'https://cdn.dkycn.cn/melyshop/image/20210810-bdd81147-75dd-478c-ab8f-8c361445adfd.png'
				}],
				helpData: {},
				visible: false
			}
		},
		created() {},
		beforeMount() {},
		mounted() {
			this.getData();
		},
		methods: {
			async getData() {
				try {
					const res = await qyweixinsidebarconfig();
					if (res.Result.IsFinishConfig) {
						this.isBuild = true;
					} else {
						this.isBuild = false;
					}
					this.result = res.Result;
					this.ruleForm.AgentId = res.Result.AgentId || '';
					this.ruleForm.Secret = res.Result.Secret || '';

					if (res.Result.TestingFile) {
						if (res.Result.TestingFile.split('/').length > 1) {
							this.ruleForm1.TestingFile = res.Result.TestingFile.split('/')[1];
						} else {
							this.ruleForm1.TestingFile = res.Result.TestingFile;
						}
					}

				} catch (e) {
					//TODO handle the exception
				}
			},
			onCopy() {
				this.$message({
					message: "复制成功！",
					type: 'success'
				});

			},
			onError() {
				this.$message({
					message: "复制失败！",
					type: 'error'
				});
			},
			handleHelp(index){
				this.helpData = this.helpList[index];
				this.visible = true;
			},
			handleBeforeUpload(file) {
				this.fileName = file.name;
				const isText = file.type === 'text/plain';
				const isLt1M = file.size / 1024 / 1024 < 1;

				if (!isText) {
					this.$message.error('上传文件只能是 txt 格式!');
				}
				if (!isLt1M) {
					this.$message.error('上传文件大小不能超过 1MB!');
				}
				return isText && isLt1M;
			},
			handleSuccess(res) {
				this.ruleForm1.file = this.fileName;
				this.$refs['ruleForm1'].validate((valid) => {
				});
			},
			handleNext() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.$refs['ruleForm1'].validate((valid) => {
							if (valid) {
								this.save();
							}else{
								this.$message.error('请完成配置')
							}
						});
					}else{
						this.$message.error('请完成配置')
					}
				});
			},
			async save(){
				try{
					const res = await setqyweixinsidebarconfig({
						AgentId: this.ruleForm.AgentId,
						Secret: this.ruleForm.Secret,
						TestingFile: '/'+this.ruleForm1.file
					})
					
					this.$message.success('保存成功');
					this.$router.back()
					
				}catch(e){
					//TODO handle the exception
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.create-app {

		margin-bottom: 150px;

		.title {
			font-size: 17px;
			font-weight: bold;
		}

		.step-tip {
			padding: 8px 10px;
			font-size: 14px;
			border: 1px solid #409EFF;
			line-height: 22px;
			min-height: 22px;
			background-color: #fbfdff;
			margin-top: 20px;
			max-width: 900px;
			color: #666;

			>div {
				margin-top: 10px;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
			}
		}

		.set-info {
			padding: 10px 20px;
			background-color: #f9f9f9;
			border: 1px solid #eee;
			margin-left: 15px;
			max-width: 850px;

			display: flex;
			overflow: hidden;

			.left {
				flex: 1 1 auto;
				overflow: hidden;
				color: #666;

				.tit {
					font-size: 15px;
					margin-bottom: 10px;
				}

				.txt {
					font-size: 14px;
					color: #333;
					word-break: break-all;
				}
			}

			.copy {
				flex: 0 0 auto;
				margin-left: 40px;
				margin-top: 10px;
			}
		}

		.step3-tip {
			font-size: 14px;
			margin: 20px 0 10px 15px;
		}

		.upload-wraper {
			display: flex;
		}

		.footer {
			position: fixed;
			bottom: 0;
			right: 0;
			left: 220px;
			text-align: center;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #fff;
			border-top: 1px solid #ddd;
		}

		@media screen and (max-width: 1280px) {
			.footer {
				left: 140px;
			}
		}
	}
</style>
